import { useNavigate } from 'react-router-dom'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Group, Stack, Text, useDisclosure } from 'summon-ui/mantine'
import { BorderedTitle, InfoCard } from '@components'
import useConvertCredits from '@features/staking/hooks/useConvertCredits'
import ConvertCreditsModal from '../components/ConvertCreditsModal'
import LockupBanner from '../components/LockupBanner'
import StakeInfoStepper from '../components/StakeInfoStepper/StakeInfoStepper'
import StakingPositions from '../components/StakingPositions'

const STEPS = [
  {
    title: 'Convert Credits',
    image: '/images/staking/stepper/g7-cards.png',
    description: 'Your credits will convert directly to G7 tokens on the new G7 chain.'
  },
  {
    title: 'Lockup or Stake',
    image: '/images/staking/stepper/red-g7-coin.png',
    description:
      'You can either stake your tokens for minimal Diamond rewards or lock them up for 12 or 24 months for big multipliers on your Diamonds.'
  },
  {
    title: 'Earn Diamonds',
    image: '/images/staking/stepper/blue-g7-diamond.png',
    description:
      'Diamonds are a new in-app currency that will allow you to buy Loot Boxes filled with prizes worth over $1 million in aggregate.'
  }
]

const StakingView = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { creditsBalance } = useConvertCredits({})
  const [opened, { close, open }] = useDisclosure(false)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })

  const handleGoToOptions = () => {
    navigate('/staking/options', { replace: true })
  }

  return (
    <>
      <BorderedTitle fz={{ xs: 'h1', md: 71, lg: 100.5 }} tt='uppercase' bordeWidth={2} borderColor='brand.6'>
        {t('Lockup & Staking')}
      </BorderedTitle>
      <Text maw='640'>
        {t(
          'Stake or lockup your tokens to earn Daily Diamond rewards. The more Diamonds you earn, the more G7 Boxes and rewards you can win.'
        )}
      </Text>
      <Stack gap='lg' mt='lg'>
        <Group>
          <Button variant='white' onClick={open}>
            {t('Convert credits')}
          </Button>
          <Button onClick={handleGoToOptions}>{t('Stake now')}</Button>
        </Group>
        <Flex gap='lg' direction={isMobile ? 'column' : 'row'}>
          <InfoCard info={creditsBalance} description={t('Available Credits')} />
          <InfoCard color='red.5' info='0 G7' description={t('My Staked Tokens')} />
          <InfoCard color='blue.6' info='0' description={t('Diamonds (15,000 bonus incl)')} />
        </Flex>
        <StakingPositions />
        <LockupBanner onClick={handleGoToOptions} />
        <StakeInfoStepper steps={STEPS} />
      </Stack>
      <ConvertCreditsModal open={opened} onClose={close} />
    </>
  )
}
export default StakingView
