import { useReadContract } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { getTokenMetadata } from '@api/client'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import { isStaging } from '@utils/isStaging'

interface Props {
  tokenId: string
  enabled?: boolean
}

const useRedeemableMetadata = ({ enabled = false, tokenId }: Props) => {
  // Dynamically create the input object, excluding undefined properties

  const { data: tokenURI } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.avatar : TENANT_CONTRACTS.mainnet.avatar,
    abi: AVATAR_BOUND_ABI,
    functionName: 'tokenURI',
    chainId: TENANT_CONFIG.network.id,
    args: [tokenId],
    query: { enabled: !!tokenId && enabled }
  })

  const { data: onchainItem } = useQuery({
    queryKey: ['inventoryItemWithMetadata', { tokenURI: tokenURI }],
    queryFn: getTokenMetadata,
    enabled: !!tokenURI && enabled
  })
  return {
    token: enabled ? onchainItem : null
  }
}

export default useRedeemableMetadata
