import { useTranslation } from 'summon-ui/intl'
import { BorderedTitle } from '@components'

const DiamondsView = () => {
  const t = useTranslation()

  return (
    <BorderedTitle
      fz={{ xs: 'h1', md: 71, lg: 100.5 }}
      bordeWidth={2}
      tt='uppercase'
      ta='center'
      borderColor='skyblue.6'
    >
      {t('Unlock rare rewards with the G7 box!')}
    </BorderedTitle>
  )
}
export default DiamondsView
