import { useState } from 'react'

interface Props {
  onConversionSuccess?: () => void
}

const useConvertCredits = ({ onConversionSuccess }: Props) => {
  const MOCKED_CREDITS_BALANCE = '1285'
  const MOCKED_G7_TOKENS_BALANCE = '0'
  const [creditsBalance, setCreditsBalance] = useState(MOCKED_CREDITS_BALANCE)
  const [g7TokensBalance, setG7TokensBalance] = useState(MOCKED_G7_TOKENS_BALANCE)
  const [isLoading, setIsLoading] = useState(false)

  const convertCreditsMutation = () => {
    setIsLoading(true) // Set loading to true when mutation starts

    // Simulate API call delay
    setTimeout(() => {
      // Mocked conversion behavior
      const newCreditsBalance = '0'
      const newG7TokensBalance = '2983'
      setCreditsBalance(newCreditsBalance)
      setG7TokensBalance(newG7TokensBalance)

      setIsLoading(false) // Set loading to false when mutation completes

      if (onConversionSuccess) {
        onConversionSuccess()
      }
    }, 3000) // Simulating a 3-second delay
  }

  return {
    creditsBalance,
    g7TokensBalance,
    convertCreditsMutation,
    isConvertCreditsMutationLoading: isLoading
  }
}

export default useConvertCredits
