export enum ArtStyleEnum {
  Art3D,
  Art2D
}

export enum Tenant {
  G7 = 'Game7'
}

export enum Hosts {
  G7 = 'app.game7.io',
  G7Staging = 'staging.summon.xyz'
}

export enum Environment {
  Staging = 'staging',
  Production = 'production'
}

export enum AssetsPrefix {
  G7 = 'https://assets.summon.xyz/game7/portal'
}

export enum LevelUpVersion {
  V1,
  V2
}
