/* eslint-disable react/display-name, @typescript-eslint/no-explicit-any */
import { useCallback, useState, type ComponentType } from 'react'
import useWebSocket from 'react-use-websocket'
import { Spinner, TenantImageEnum, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Image, Button, Title, Stack, Text, Group, Center } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { useMutation } from '@tanstack/react-query'
import { addToQueue } from '@api/client'
import { Turnstile } from '@marsidev/react-turnstile'
import { isStaging } from '@utils/isStaging'
import { hasJsonStructure } from '@utils/strings'

const TURNSTILE_PROD_SITEKEY = '0x4AAAAAAAhkmgwuhAEp2ln_'
// Dummy sitekeys can be used from any domain, this always passes the challenge
const TURNSTILE_TEST_SITEKEY = '1x00000000000000000000AA'

const wss = document.location.protocol === 'http:' ? 'ws://' : 'wss://'
const socket = wss + 'waitingroom.summon.xyz/waitingroom/websocket?token=12345'

enum QUEUE_ACTIONS {
  Mint = 'mint',
  Position = 'position',
  Remove = 'remove'
}

type SocketResponse = {
  ready: boolean | null
  position: number | null
  estimated: number | null
  message: string
  waiting: number | null
}

type WaitingRoomProps = {
  action: () => Promise<any>
  close: () => void
  disabled: boolean
  isLoading: boolean
  isSponsored?: boolean | null
}

const withWaitingRoom = (WrappedComponent: ComponentType<any>) => (props: WaitingRoomProps) => {
  const t = useTranslation()
  const { getTenantImage } = useAssetsContext()
  const mintAvatarImage = getTenantImage(TenantImageEnum.MintAvatar)
  const { action, close, isSponsored } = props
  const { address } = useAccount()
  const [currentSocketUrl, setCurrentSocketUrl] = useState<string | null>(null)
  const [isInWaitingRoom, setIsInWaitingRoom] = useState(true)
  const [turnstileStatus, setTurnstileStatus] = useState<string | null>(null)

  const [roomInfo, setRoomInfo] = useState<SocketResponse>({
    ready: false,
    position: null,
    estimated: null,
    message: '',
    waiting: null
  })

  const queue = useCallback(
    (action: QUEUE_ACTIONS) => {
      sendJsonMessage({
        message: address,
        action: action
      })
    },
    [address]
  )

  const { mutateAsync: addToQueueMutation, isPending } = useMutation({
    mutationKey: ['queue_add'],
    mutationFn: () => addToQueue(address as `0x${string}`),
    onSuccess: () => {
      setCurrentSocketUrl(socket)
    }
  })
  const { sendJsonMessage } = useWebSocket(currentSocketUrl, {
    onOpen: () => {
      queue(QUEUE_ACTIONS.Position)
    },

    shouldReconnect: () => true,
    onMessage: ({ data }: { data: string }) => {
      const response = JSON.parse(data) as { message: string; status: 0 | 1 }
      if (hasJsonStructure(response.message) && response.status) {
        setIsInWaitingRoom(true)
        setRoomInfo(JSON.parse(response.message) as SocketResponse)
      } else if (isInWaitingRoom) {
        setIsInWaitingRoom(false)
        setCurrentSocketUrl(null)
      }
    }
  })
  const ready = roomInfo.ready

  const closeWaitingRoom = () => {
    queue(QUEUE_ACTIONS.Remove)
    close()
  }
  const checkNextStep = async () => {
    addToQueueMutation()
  }

  const mintAction = () => {
    action().then(() => {
      !isSponsored && queue(QUEUE_ACTIONS.Mint)
      close()
    })
  }

  if (currentSocketUrl || isSponsored) {
    return isInWaitingRoom ? (
      <Stack p='sm' gap='sm'>
        <Image src={mintAvatarImage} radius='md' h={200} ta='center' />
        <Title order={3}>{t('Prepare for an Epic Journey')}</Title>
        <Center w='100%'>
          <Stack gap='xs'>
            <Text>
              {t(
                'Create your free Avatar to unlock exclusive wearables, special features, and unique partner experiences. Your adventure begins now!'
              )}
            </Text>
            {!ready && !isSponsored && (
              <Text>
                {t('Estimated queue:')}{' '}
                <Text fw='700'>
                  {roomInfo?.position}/{roomInfo.waiting}
                </Text>{' '}
                {t('Thanks for your patience!')}
              </Text>
            )}
          </Stack>
        </Center>
        {!isSponsored && (
          <Stack gap='0'>
            <Text>{t('Transaction fee payment method')}:</Text>
            <Text>{t('The fee will cover the blockchain transaction cost.')}</Text>
          </Stack>
        )}
        <Turnstile
          siteKey={isStaging ? TURNSTILE_TEST_SITEKEY : TURNSTILE_PROD_SITEKEY}
          onSuccess={() => setTurnstileStatus('solved')}
        />
        <Group justify='space-between'>
          <Button miw={120} variant='outline' onClick={closeWaitingRoom}>
            {t('Close')}
          </Button>
          <Button
            data-testid='waiting-room-mint-button'
            disabled={(!ready && !isSponsored) || turnstileStatus != 'solved'}
            loading={props.isLoading}
            miw={120}
            onClick={mintAction}
          >
            {t('Mint')}
          </Button>
        </Group>
      </Stack>
    ) : (
      <Center h={300}>
        <Stack gap='xs'>
          <Spinner isFull />
          <Text ta='center'>{t('Connecting to mint')}...</Text>
        </Stack>
      </Center>
    )
  }

  return <WrappedComponent {...props} isLoading={isPending} nextStep={checkNextStep} />
}

export default withWaitingRoom
