import { Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Group, Stack } from 'summon-ui/mantine'
import { useRedeemables } from '@hooks'
import NoItemsMessage from '../NoItemsMessage'
import Redeemable from './Redeemable'

const NO_CLAIMABLES_TITLE = 'No Claimables Available'
const NO_CLAIMABLES_SUBTITLE =
  'Claimables are unlocked when you complete certain quests, missions, or attend special events. Keep questing and stay involved'

const Redeemables = () => {
  const t = useTranslation()
  const { rewardsRedeemables, isRewardsRedeemablesLoading } = useRedeemables({ active: true })
  const hasRedeemables = rewardsRedeemables && rewardsRedeemables?.length > 0

  if (isRewardsRedeemablesLoading) {
    return (
      <Center className='h-80'>
        <Spinner />
      </Center>
    )
  }

  return (
    <Stack h='100%' mt='md'>
      {hasRedeemables ? (
        <Group>
          {rewardsRedeemables?.map(({ id, title, imageUrl, redeemed, rewardIntegration: { additionalData } }) => {
            return (
              <Redeemable
                key={id}
                tokenId={additionalData.rewardTokenId?.toString()}
                redeemableId={id}
                title={title}
                imageUrl={imageUrl}
                isSponsored
                isRedeemed={redeemed}
              />
            )
          })}
        </Group>
      ) : (
        <NoItemsMessage title={t(NO_CLAIMABLES_TITLE)} message={t(NO_CLAIMABLES_SUBTITLE)} />
      )}
    </Stack>
  )
}

export default Redeemables
