import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Text, Box, Group, Image, BackgroundImage, Stack, Title, Card } from 'summon-ui/mantine'

const MOCKED_G7_TOKENS = 2983

interface Props {
  open: boolean
  onClose: () => void
}

const ConversionSuccessModal = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()

  const handleGoToStake = () => {
    navigate('/staking/options/stake', { replace: true })
  }

  // TODO: Add the dynamic data stated in the design

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Box p='xs'>
        <BackgroundImage src='/images/staking/conversion-success-bg.jpg' radius='md'>
          <Stack justify='center' align='center' py='lg' gap='xs'>
            <Image src='/images/staking/conversion-success-token.png' alt='success' w={157} />
            <Text ta='center'>{t('Tokens receive')}</Text>
            <Title order={2}>{MOCKED_G7_TOKENS} G7</Title>
          </Stack>
        </BackgroundImage>

        <Card
          mt='md'
          withBorder
          radius='md'
          style={{
            border: '1px solid #E0F2FE'
          }}
        >
          {t('Lock up tokens to earn extra Diamonds to buy more G7 Boxes')}
        </Card>

        <Group justify='space-between' wrap='nowrap' mt='lg'>
          <Button variant='outline' onClick={onClose} fullWidth>
            {t('Close')}
          </Button>
          <Button fullWidth onClick={handleGoToStake}>
            {t('Stake tokens')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default ConversionSuccessModal
