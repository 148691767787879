import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { graphqlClient } from '@api/config'
import { type InventoryInput, useEquipInventoryItemsMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import useAvatarMetadata from '@hooks/inventory/useAvatarMetadata'
import useAvatarStore from '@modules/inventory/hooks/useAvatarStore'

interface Props {
  onCallback?: () => void
  isDna?: boolean
}

const useEquipItems = ({ onCallback, isDna = false }: Props) => {
  const { viewer } = useViewer()
  const { previewItems } = useAvatarStore()
  const [isEquipSuccess, setIsEquipSuccess] = useState(false)
  const { refetchAvatarMetadata } = useAvatarMetadata({})

  const initialItems = isDna ? previewItems.filter((item) => item?.isEquipped) : previewItems

  const itemsToEquip = initialItems.map((item) => ({
    tokenId: item?.tokenId,
    isEquipped: item?.isEquipped
  }))

  const payload: InventoryInput = {
    playerAddress: viewer?.walletAddress as string,
    inventory: itemsToEquip
  }

  const { mutateAsync: equipItemsMutation, isPending: isEquippingItems } = useEquipInventoryItemsMutation(
    graphqlClient(),
    {
      onSuccess() {
        refetchAvatarMetadata()
        setIsEquipSuccess(true)
        onCallback && onCallback()
      },
      onError: () => {
        showNotification({
          variant: 'danger',
          message: 'ERROR equipping inventory Items'
        })
      }
    }
  )

  const equipItems = async () => {
    try {
      await equipItemsMutation({ payload })
    } catch (error) {
      console.error('Error equipping items: ', error)
    }
  }

  return {
    equipItems,
    isEquippingItems,
    isEquipSuccess,
    setIsEquipSuccess,
    equipItemsPayload: payload
  }
}

export default useEquipItems
