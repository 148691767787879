import { Icon } from 'summon-ui'
import { alpha, Box, Center, getThemeColor, Paper, Text, Title, Tooltip, useMantineTheme } from 'summon-ui/mantine'
import type { MantineColor, PaperProps } from 'summon-ui/mantine'

interface TootipInfo {
  label: string
  w?: string | number
}
type InfoCardProps = {
  info: string | number
  description: string
  tooltipInfo?: TootipInfo
  color?: MantineColor
  size?: 'small' | 'medium' | 'large'
} & PaperProps

const InfoCard = ({ info, description, color, tooltipInfo, size = 'medium', ...props }: InfoCardProps) => {
  const paddingY = {
    small: '4',
    medium: 'lg',
    large: 'xl'
  }[size]
  const theme = useMantineTheme()
  const titleColor = color ? getThemeColor(color, theme) : theme.colors.yellow[5]
  const borderColor = alpha(titleColor, 0.6)
  const backgroundColor = alpha(titleColor, 0.1)
  const textColor = alpha('#fff', 0.6)
  return (
    <Paper
      withBorder
      style={{
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`
      }}
      className='text-center'
      px='xs'
      py={paddingY}
      w='100%'
      radius='md'
      {...props}
    >
      <Title order={3} c={titleColor}>
        {info}
      </Title>
      <Center h='24px'>
        <Text size='xs' c={textColor} pl={tooltipInfo ? '6' : '0'}>
          {description}
        </Text>
        {tooltipInfo && (
          <Tooltip multiline withArrow label={tooltipInfo.label} w={tooltipInfo.w}>
            <Box>
              <Icon name='InfoCircle' c={textColor} />
            </Box>
          </Tooltip>
        )}
      </Center>
    </Paper>
  )
}

export default InfoCard
