import { useState } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Button, Card, Flex, Image, Stack, Text, TextInput, Title } from 'summon-ui/mantine'

type UnstakeTokensFormProps = {
  multiplier: number
  balance: number
}

const UnstakeTokensForm = ({ balance }: UnstakeTokensFormProps) => {
  const t = useTranslation()
  const [stakeAmount, setStakeAmount] = useState('')

  return (
    <Card withBorder radius='md' bg='black' p='30'>
      <Stack ta='center' gap='xs'>
        <Title>{t('Unstake Tokens')}</Title>
        <Text>{t('Unstake your G7 tokens and reclaim your credits instantly.')}</Text>
      </Stack>
      <Stack gap='md' mt='lg'>
        <Stack gap='4'>
          <Flex justify='space-between' align='center'>
            <Text size='sm'>{t('Tokens to unstake')}</Text>
            <Text size='sm'>
              {t('Balance')}: {balance} G7
            </Text>
          </Flex>
          <TextInput
            size='lg'
            labelProps={{ mb: 'xs' }}
            leftSection={<Image src='/images/staking/G7.svg' />}
            value={stakeAmount}
            onChange={(e) => setStakeAmount(e.target.value)}
            rightSection={
              <Button
                variant='transparent'
                p='0'
                mr='xs'
                fw='bold'
                c='red.5'
                onClick={() => setStakeAmount(balance.toString())}
              >
                MAX
              </Button>
            }
            placeholder={t('Token amount')}
          />
        </Stack>
      </Stack>
      <Button mt='lg' fullWidth disabled={!stakeAmount}>
        {t('Unstake')}
      </Button>
    </Card>
  )
}

export default UnstakeTokensForm
