import { AssetsProvider, ThemeProvider } from 'summon-ui'
import { IntlProvider } from 'summon-ui/intl'
import { Notifications } from 'summon-ui/mantine'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TenantDevTools } from '@components'
import { TENANT_CONFIG } from '@config'
import { TutorialProvider, WagmiProvider, AppRouter, OptimizelyCustomProvider } from '@hocs'
import en from '@lang/en.json'

const NOTIFICATIONS_SPACING = '30px'
const NOTIFICATIONS_STYLES = { root: { top: NOTIFICATIONS_SPACING, right: NOTIFICATIONS_SPACING } }
const TWENTY_FOUR_HOURS_IN_MS = 1000 * 60 * 60 * 24

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: TWENTY_FOUR_HOURS_IN_MS
    }
  }
})
const enMessages = { en }

const { name: tenantName, lang: tenantLang, uiTheme: tenantTheme } = TENANT_CONFIG

const App = () => (
  <OptimizelyCustomProvider>
    <QueryClientProvider client={queryClient}>
      <WagmiProvider>
        <AssetsProvider tenant={tenantName}>
          <IntlProvider
            intlConf={{
              currentLang: tenantLang,
              messagesConf: {
                ...enMessages
              }
            }}
          >
            <ThemeProvider uiTheme={tenantTheme}>
              <Notifications position='top-right' zIndex={1000} styles={NOTIFICATIONS_STYLES} />
              <TutorialProvider>
                <AppRouter />
                <ReactQueryDevtools initialIsOpen={false} />
                <TenantDevTools />
              </TutorialProvider>
            </ThemeProvider>
          </IntlProvider>
        </AssetsProvider>
      </WagmiProvider>
    </QueryClientProvider>
  </OptimizelyCustomProvider>
)

export default App
