import { useState, useMemo } from 'react'
import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  alpha,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  getGradient,
  Image,
  NumberFormatter,
  rem,
  Slider,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme
} from 'summon-ui/mantine'
import { useViewer } from '@hooks'

type StakeTokensFormProps = {
  balance: number
}
const StakeTokensForm = ({ balance }: StakeTokensFormProps) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const theme = useMantineTheme()
  const gradient = getGradient({ deg: 90, from: 'red.6', to: 'blue.3' }, theme)
  const [stakePeriod, setStakePeriod] = useState(50)
  const [isChecked, setIsChecked] = useState(false)
  const [stakeAmount, setStakeAmount] = useState('')

  const selectedPeriod = useMemo(() => stakePeriodMarks.find((mark) => mark.value === stakePeriod), [stakePeriod])
  const lockupMultiplier = useMemo(() => {
    switch (selectedPeriod?.months) {
      case 12:
        return 1.5
      case 24:
        return 3
      default:
        return 1
    }
  }, [selectedPeriod])
  const totalMultiplier = lockupMultiplier + (viewer?.level || 0)
  const diamonds = totalMultiplier * (Number(stakeAmount) || 0)

  const TotalMultiplierTooltip = () => {
    return (
      <>
        <Text size='xs' pb='4'>
          Breakdown:
        </Text>
        <Text size='xs'>Lockup Multiplier: {lockupMultiplier}x</Text>
        <Text size='xs'>Level Multiplier: {viewer?.level || 0}x</Text>
        <Divider my='6' />
        <Text size='xs'>Total multiplier: {totalMultiplier}x</Text>
      </>
    )
  }

  // TODO: get calculated diamonds
  return (
    <Card withBorder radius='md' bg='black' p='30'>
      <Stack ta='center' gap='xs'>
        <Title>{t('Stake Tokens')}</Title>
        <Text>{t('Stake or Lockup G7 tokens to earn Diamonds.')}</Text>
      </Stack>
      <Stack gap='md' mt='lg'>
        <Stack gap='4'>
          <Flex justify='space-between' align='center'>
            <Text size='sm'>{t('Tokens to stake')}</Text>
            <Text size='sm'>
              {t('Balance')}: {balance} G7
            </Text>
          </Flex>
          <TextInput
            size='lg'
            labelProps={{ mb: 'xs' }}
            leftSection={<Image src='/images/staking/G7.svg' />}
            value={stakeAmount}
            onChange={(e) => setStakeAmount(e.target.value)}
            rightSection={
              <Button
                variant='transparent'
                p='0'
                mr='xs'
                fw='bold'
                c='red.5'
                onClick={() => setStakeAmount(balance.toString())}
              >
                MAX
              </Button>
            }
            placeholder={t('Token amount')}
          />
        </Stack>
        <Box>
          <Flex align='center' mb='xs'>
            <Title order={5}>{t('Lockup period')}</Title>
            <Tooltip
              withArrow
              multiline
              label={t(
                "A lockup period means that you will not be able to withdraw your tokens for the entire lockup period you choose. In exchange, you'll get more Diamond rewards each day."
              )}
              w={200}
              position='right'
            >
              <Box>
                <Icon name='InfoCircle' />
              </Box>
            </Tooltip>
          </Flex>
          <Card radius='md' withBorder p='8'>
            <Flex gap='4' align='center'>
              <Icon name='InfoCircle' /> <Text>{t('You could be earning 2x-4x more Diamonds by locking up.')}</Text>
            </Flex>
          </Card>
          <Slider
            value={stakePeriod}
            onChange={(value) => {
              setStakePeriod(value)
              setIsChecked(false)
            }}
            my='md'
            label={(val) => stakePeriodMarks.find((mark) => mark.value === val)?.label ?? ''}
            step={50}
            marks={stakePeriodMarks}
            color='yellow.6'
            styles={{
              bar: { boxShadow: '0px 0px 13.737px 2.29px #E43B3B' },
              thumb: {
                width: rem(20),
                height: rem(20),
                border: 0,
                backgroundColor: 'white',
                boxShadow: '0px 0px 13.737px 5px #EAAA08'
              }
            }}
          />
        </Box>
        <Box>
          <Title mb='xs' order={5}>
            {t('Rewards')}
          </Title>
          <Card withBorder bg={gradient} radius='md' p='0'>
            <Stack m='1' p='sm' className='rounded-lg' gap='xs' bg={alpha('white', 0.8)}>
              <Flex justify='space-between'>
                <Flex align='center'>
                  <Text>{t('Total Multiplier')}</Text>
                  <Tooltip withArrow multiline label={<TotalMultiplierTooltip />} w={150} position='right'>
                    <Box>
                      <Icon name='InfoCircle' />
                    </Box>
                  </Tooltip>
                </Flex>
                <Text fw='bold'>{totalMultiplier}x</Text>
              </Flex>
              <Flex justify='space-between'>
                <Flex align='center'>
                  <Text>{t('Daily Diamonds')}</Text>
                </Flex>
                <Flex gap='xs' align='center'>
                  <Image src='/images/staking/diamond.png' className='w-4' />
                  <NumberFormatter className='font-bold' value={diamonds} thousandSeparator />
                </Flex>
              </Flex>
            </Stack>
          </Card>
        </Box>
      </Stack>
      {selectedPeriod && (
        <Flex gap='md' mt='lg'>
          <Checkbox checked={isChecked} onChange={(event) => setIsChecked(event.currentTarget.checked)} mt='4' />
          <Box>
            <Title order={5}>{t('Lockup Agreement')}</Title>
            <Text>
              {t(
                'By clicking the Lockup button below, I understand and accept that my tokens will be locked and inaccessible for {selectedPeriodMonths} months, with a 72-hour cooldown period when unstaking.',
                { selectedPeriodMonths: selectedPeriod?.months }
              )}
            </Text>
          </Box>
        </Flex>
      )}

      <Button mt='lg' fullWidth disabled={!stakePeriod || !isChecked || !stakeAmount}>
        {t('Lockup')}
      </Button>
    </Card>
  )
}

const stakePeriodMarks = [
  { value: 0, label: '0', months: 0 },
  { value: 50, label: '12 Months', months: 12 },
  { value: 100, label: <span className='mr-10'>24 Months</span>, months: 24 }
]
export default StakeTokensForm
