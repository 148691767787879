import { type PropsWithChildren } from 'react'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'

const optimizelyClient = createInstance({ sdkKey: import.meta.env.VITE_OPTIMIZELY_TOKEN })

function isClientValid() {
  return optimizelyClient.getOptimizelyConfig() !== null
}

const OptimizelyCustomProvider = ({ children }: PropsWithChildren) => {
  optimizelyClient.onReady().then(() => {
    isClientValid()
  })

  return <OptimizelyProvider optimizely={optimizelyClient}>{children}</OptimizelyProvider>
}

export default OptimizelyCustomProvider
