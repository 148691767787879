import { useState } from 'react'
import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Modal,
  Button,
  Title,
  Text,
  Box,
  Stack,
  Group,
  Checkbox,
  Image,
  Card,
  NumberFormatter,
  useDisclosure
} from 'summon-ui/mantine'
import useConvertCredits from '../hooks/useConvertCredits'
import ConversionSuccessModal from './ConversionSuccessModal'

// TODO: Add real data
const MOCKED_TOKENS_TO_RECEIVE = 1285

interface Props {
  open: boolean
  onClose: () => void
}

const ConvertCreditsModal = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const [checked, setChecked] = useState(false)
  // TODO: Add real data logic
  const [stakingSuccessModalOpened, { close: stakingSuccessModalClose, open: stakingSuccessModalOpen }] =
    useDisclosure(false)
  const handleSuccessConversion = () => {
    onClose()
    stakingSuccessModalOpen()
  }
  const { creditsBalance, convertCreditsMutation, isConvertCreditsMutationLoading } = useConvertCredits({
    onConversionSuccess: handleSuccessConversion
  })

  return (
    <>
      <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md' size='lg'>
        <Box p='xs'>
          <Stack gap='0' align='center' mb='lg'>
            <Title order={1}>{t('Convert Credits')}</Title>
            <Text ta='center' maw={386}>
              {t('Convert your credits from your completed quests into G7 tokens')}
            </Text>
          </Stack>

          <Stack gap='lg' align='center' mb='lg'>
            <Group gap='xs' wrap='nowrap'>
              <Card
                withBorder
                radius='md'
                style={{
                  border: `1px solid #FDE272`
                }}
              >
                <Card.Section>
                  <Image src='/images/staking/credits-convert.png' alt='credits to convert' />
                </Card.Section>
                <Stack align='center' gap='0'>
                  <Title order={2} c='yellow.5'>
                    <NumberFormatter thousandSeparator value={creditsBalance} />
                  </Title>
                  <Text c='yellow.1'>{t('Credits to convert')}</Text>
                </Stack>
              </Card>

              <Icon name='ChevronRightDouble' size='lg' c='dimmed' />

              <Card
                withBorder
                radius='md'
                style={{
                  border: `1px solid #FDA29B`
                }}
              >
                <Card.Section>
                  <Image src='/images/staking/tokens-receive.png' alt='tokens to receive' />
                </Card.Section>
                <Stack align='center' gap='0'>
                  <Title order={2} c='error.5'>
                    <NumberFormatter thousandSeparator value={MOCKED_TOKENS_TO_RECEIVE} />
                  </Title>
                  <Text c='error.1'>{t('Tokens to receive')}</Text>
                </Stack>
              </Card>
            </Group>
            <Card withBorder radius='md'>
              <Checkbox
                data-autofocus
                label={
                  <>
                    {/* TODO: Add numbers dynamically + translation */}
                    <Text>
                      By clicking Convert button, I understand and accept that this is a one time, non-reversible
                      action. In return I convert credits and receive G7 tokens.
                    </Text>
                  </>
                }
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
                size='md'
                mt='md'
              />
            </Card>
          </Stack>

          <Group justify='space-between' wrap='nowrap' mt='lg'>
            <Button
              variant='outline'
              onClick={onClose}
              fullWidth
              loading={isConvertCreditsMutationLoading}
              disabled={isConvertCreditsMutationLoading}
            >
              {t('Close')}
            </Button>
            <Button
              fullWidth
              onClick={convertCreditsMutation}
              loading={isConvertCreditsMutationLoading}
              disabled={!checked || isConvertCreditsMutationLoading}
            >
              {t('Convert')}
            </Button>
          </Group>
        </Box>
      </Modal>
      <ConversionSuccessModal open={stakingSuccessModalOpened} onClose={stakingSuccessModalClose} />
    </>
  )
}

export default ConvertCreditsModal
