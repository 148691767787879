import { useEffect, useMemo, useState } from 'react'
import useAvatarMetadata, { type InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import useAvatarStore, { type AvatarState } from '@modules/inventory/hooks/useAvatarStore'
import useBaseSkins from './useBaseSkins'
import useDnaItems from './useDnaItems'

interface Props {
  isDNA?: boolean
  isDNATraits?: boolean
}

const useInventoryItems = ({ isDNA = false, isDNATraits = false }: Props) => {
  const { baseSkins } = useBaseSkins({ enabled: isDNA })
  const { dnaItems, isDnaItemsLoading } = useDnaItems({ enabled: isDNATraits })
  const { avatarMetadata, isAvatarMetadataLoading } = useAvatarMetadata({ enabled: !isDNA && !isDNATraits })
  const [loading, setLoading] = useState(true)

  const items = useMemo(() => {
    switch (true) {
      case isDNATraits:
        return dnaItems ?? []
      case isDNA:
        return baseSkins ?? []
      default:
        return (avatarMetadata?.inventory?.items as InventoryItem[]) ?? []
    }
  }, [isDNATraits, dnaItems, isDNA, baseSkins, avatarMetadata])

  const setItems = useAvatarStore((state: AvatarState) => state.setItems)
  useEffect(() => {
    if (items?.length) {
      setItems(items as InventoryItem[])
      setLoading(false)
    }
  }, [items])

  const isItemsLoading = isDNATraits ? isDnaItemsLoading : loading || isAvatarMetadataLoading

  return {
    inventoryItems: (items as InventoryItem[]) || [],
    isInventoryItemsLoading: isItemsLoading
  }
}

export default useInventoryItems
