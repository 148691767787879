import { createBrowserRouter } from 'react-router-dom'
import { ErrorBoundary, ScrollToTop } from '@components'
import { TENANT_CONFIG } from '@config'
import LayoutSwitcher from '@layouts/LayoutSwitcher'
import OnboardingLayout from '@layouts/OnboardingLayout/OnboardingLayout'
import { isStaging } from '@utils/isStaging'
import CreditsPage from './pages/CreditsPage'
import DiamondsPage from './pages/DiamondsPage'
import HistoryPage from './pages/HistoryPage'
import LeaderboardPage from './pages/LeaderboardPage'
import LootdropsPage from './pages/LootdropsPage'
import MaintenancePage from './pages/MaintenancePage'
import MarketplacePage from './pages/MarketplacePage'
import QuestsPage from './pages/QuestsPage'
import RedirectPage from './pages/RedirectPage'
import SettingsPage from './pages/SettingsPage'
import StakingPage from './pages/StakingPage'
import OnboardingPage from './pages/auth/OnboardingPage'
import HomePage from './pages/home'

export const maintenanceRouter = createBrowserRouter([
  {
    element: <MaintenancePage />,
    path: '/*'
  }
])

const router = createBrowserRouter([
  {
    element: <RedirectPage />,
    path: '/callback'
  },
  {
    element: <MaintenancePage />,
    path: '/lockdown'
  },
  {
    element: <OnboardingLayout />,
    children: [
      {
        path: '/onboarding/*',
        element: <OnboardingPage />,
        errorElement: <ErrorBoundary />
      }
    ]
  },
  {
    element: (
      <ScrollToTop>
        <LayoutSwitcher />
      </ScrollToTop>
    ),
    children: [
      {
        path: '/quests/*',
        element: <QuestsPage />
      },
      ...(TENANT_CONFIG.features.lootdrops
        ? [
            {
              path: '/lootdrop/*',
              element: <LootdropsPage />
            }
          ]
        : []),
      {
        path: '/history/*',
        element: <HistoryPage />
      },
      {
        path: '/credits/*',
        element: <CreditsPage />
      },
      ...(isStaging
        ? [
            {
              path: '/staking/*',
              element: <StakingPage />
            },
            {
              path: '/Diamonds/*',
              element: <DiamondsPage />
            }
          ]
        : []),
      {
        path: '/settings/*',
        element: <SettingsPage />
      },
      {
        path: '/leaderboard/*',
        element: <LeaderboardPage />
      },
      {
        path: '/marketplace/*',
        element: <MarketplacePage />
      },
      {
        path: '/*',
        element: <HomePage />
      }
    ]
  }
])

export default router
