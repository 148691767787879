import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Stack, Text, Image, Paper, Center, Divider } from 'summon-ui/mantine'
import type { Maybe } from '@generated/generates'
import { useRedeem } from '@hooks'
import useRedeemableMetadata from '@hooks/rewards/useRedeemableMetadata'

interface Props {
  redeemableId: string
  isRedeemed: boolean
  title: string
  imageUrl: Maybe<string> | undefined
  isSponsored: boolean
  tokenId?: string
}

const Redeemable = ({ redeemableId, isRedeemed, title, imageUrl, isSponsored, tokenId }: Props) => {
  const t = useTranslation()
  const { getTenantImage } = useAssetsContext()
  const misteryBoxImage = getTenantImage(TenantImageEnum.MisteryBox)

  const { redeem, isRedeeming } = useRedeem({
    redeemableId,
    isSponsored
  })

  const { token } = useRedeemableMetadata({
    enabled: isRedeemed && !!tokenId,
    tokenId: tokenId || ''
  })
  return (
    <Paper withBorder radius='md' className='aspect-square size-48' p='sm'>
      <Stack gap='xs'>
        <Center h={120} mb='sm'>
          {token ? (
            <Image radius='md' w='auto' h='100%' fit='contain' alt='claimable' src={token.image} />
          ) : (
            <Image
              radius='md'
              w='auto'
              h='100%'
              fit='contain'
              alt='claimable'
              src={imageUrl || misteryBoxImage}
              fallbackSrc='https://placehold.co/600x400?text=Placeholder'
            />
          )}
        </Center>
      </Stack>
      {isRedeemed ? (
        <Center>
          <Divider h='2' />
          <Stack gap='0' ta='center'>
            <Text ta='center' fw={500} lineClamp={2}>
              {title}
            </Text>
          </Stack>
        </Center>
      ) : (
        <Button fullWidth onClick={redeem} loading={isRedeeming}>
          {t('Claim')}
        </Button>
      )}
    </Paper>
  )
}

export default Redeemable
